import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=5d7f570d&scoped=true"
import script from "./edit.vue?vue&type=script&lang=js"
export * from "./edit.vue?vue&type=script&lang=js"
import style0 from "./edit.vue?vue&type=style&index=0&id=5d7f570d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d7f570d",
  null
  
)

export default component.exports