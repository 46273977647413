<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >运营与营销</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/adv' }">广告管理</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info margin-l-sm">
            <el-form ref="form" :model="form" :rules="rules">

                <el-form-item label="类型：" label-position="left" class="input">
                    <el-select style="width: 200px" v-model="form.type" placeholder="请选择" @change="selectType">
                        <el-option
                            v-for="item in types"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
               <el-form-item label="位置：" label-position="left" class="input">
                   <el-select style="width: 200px" v-model="form.position" placeholder="请选择" @change="selectPositionType">
                       <el-option
                           v-for="item in positions"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                       </el-option>
                   </el-select>
               </el-form-item>
				
				<el-form-item label="链接" label-position="left" class="input" prop="link">
				    <el-input style="width: 400px;"  v-model="form.link"></el-input>
				</el-form-item>

                <el-form-item label="图片：" label-position="left" prop="pic" >
                        <select-images @onSelect="onSelect" @onDelete="onDelete" :image="image"></select-images>
                </el-form-item>
				<el-form-item label="状态：" label-position="left" class="input">
				    <el-switch v-model="form.status" :active-value="1" :inactive-value="2" @change="setStatus">
				    </el-switch>
				    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
				</el-form-item>
				
                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex"
	import { config } from '../../../js/config'
    import SelectImages from "../../../components/selectImages/index";
    export default {
        name: "template-add",
        components:{SelectImages},
        data() {
            return {
				uploadPath:'adv',
                statusTxt:'启用',
                loading:false,
                image:'',
                form: {},
                types: [
                    {
                        value: 1,
                        label: '小程序'
                    },
                    {
                        value: 2,
                        label: 'H5'
                    }
                ],
				positions: [
					{
						value: 1,
						label: '圈子'
					},
					{
						value: 2,
						label: '书房'
					}
				],
				
				rules: {
					link: [
							{ required: true, message: '链接必须', trigger: 'blur' },
						  ],
				    pic: [
						{ required: true, message: '图片必须', trigger: 'blur' }
					]
				},
				path: config.imageUrl
            }
        },
		created() {
			var id = this.$route.params.id
			this.getDetail(id)
		},
        methods: {
            ...mapActions('adv',['addAdv','getAdvDetail']),
            uploadSuccess(path){
                this.form.pic = path
            },
            onSelect(image){
                this.image =  imageUrl + image.path
                this.form.pic = image.path
            },
            onDelete(){
                this.image = ''
            },
			onSubmit(){
				this.$refs['form'].validate((valid) => {
					  if (valid) {
						this.add()
					  } else {
						return false
					  }
				});
			},
            async add() {
                let _this = this
                this.loading = true
                await this.addAdv(this.form).then( res => {
                    if(res.ret == 0){
                        _this.$message.success('编辑成功！')
                        _this.$router.push('/adv')
                    }
                })
                this.loading = false
            },
            setStatus(status){
                if(this.form.status === 1){
                    this.statusTxt = '启用'
                }else{
                    this.statusTxt = '禁用'
                }
                this.form.status = status
            },
            selectType(val){
                this.form.type = val
            },
			selectPositionType(val) {
				this.form.position = val
			},
			async getDetail(id){
				const {res_info, data} = await this.getAdvDetail({id:id})
				if(res_info !== 'ok') return
				this.form = data
                if(this.form.pic) this.image = config.imageUrl + this.form.pic
			}
        }
    }
</script>

<style scoped>
	.margin-l-sm {
		margin-left: 1.25rem;
	}
</style>
